<template>
  <nav :class="'navbar bg-' + background_color">
    <div class="container-fluid">
      
      <div class="navbar-brand bars" @click="toggled()">
        <span style="display: -webkit-inline-box;">
          <font-awesome-icon :class="'nav-icon text-' + color" :icon="['fa', 'bars']" />
        </span>
      </div>

      <span class="navbar-left">
        <div class="navbar-brand">
          <span style="display: -webkit-inline-box;">
            <font-awesome-icon :class="'nav-icon text-' + color" :icon="['fa', 'bell']" style="margin-right: 0" />
          </span>
        </div>        

        <div class="navbar-brand dropdown">
          <span data-bs-toggle="dropdown" class="dropdown-toggle" style="display: -webkit-inline-box;">
            <font-awesome-icon :class="'nav-icon text-' + color" :icon="['fa', 'user']" style="margin-right: 0" />
          </span>

          <div class="dropdown-menu">
            <span class="account">
              <p class="account-text" style="display: inline-block">Hey, {{ user.name.split(' ')[0]  }}!</p>
            </span>
            
            <router-link :to="{name: 'Profile'}" class="dropdown-item">
              <font-awesome-icon class="icon" :icon="['fa', 'user']" /> 
              <p class="dropdown-list"> My Account </p>
            </router-link>

            <router-link :to="{name: 'AdminUsers'}" class="dropdown-item">
              <font-awesome-icon class="icon" :icon="['fa', 'user']" /> 
              <p class="dropdown-list"> Administration </p>
            </router-link>

            <div class="dropdown-divider"></div>

            <a class="dropdown-item logout" @click="logout()">
              <font-awesome-icon class="icon logout" :icon="['fa', 'sign-out-alt']" />
              <p class="dropdown-list"> Logout </p>
            </a>
          </div>
        </div>
      </span>

      <profile></profile>

      <div class="divider mt-4 d-none"></div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
    @import '../assets/scss/layout/_navbar.scss';

    .dropdown-item {
      cursor: pointer;
    }
    
</style>

<script>
import Profile from '../components/Profile';

export default {
    name: 'Navbar',
    props: {
      background_color: {
        type: String,
        required: true
      },
      sidebar: {
        required: false,
        type: Boolean
      },
      user: {
        required: true,
        type: Object
      },
      color: {
        required: true,
        type: String
      },
      chosen_stock: {
        required: false,
        type: Array
      }
    },
    components: {
      Profile
    },
    methods: {
      toggled() { 
        document.querySelector('#sidebar-wrapper').classList.add('toggled');
        document.querySelector('.backdrop').classList.add('on');
      },
      logout() {
        localStorage.removeItem('user')

        setTimeout(() => {
          this.$router.push({name: 'Login'});
        }, 1000);
      }
    }
}
</script>